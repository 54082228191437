import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { ethers, BigNumber } from 'ethers'
import { mainnet, devCommunity, stagingCommunity, OpticsContext } from 'optics-multi-provider-community'
import { TransferMessage } from 'optics-multi-provider-community/dist/optics/messages/BridgeMessage'
import { mainnetCommunityDomains, TokenIdentifier } from 'optics-multi-provider-community/dist/optics'

import { TXData } from './transactions'
import * as types from '../mutation-types'
import { networks } from '../../config/index'
import { getBalance, getNativeBalance, getERC20Balance } from '../../utils/balance'
import { getNetworkByChainID, getNetworkByDomainID, isNativeToken } from '../../utils/index'
import { getTimestampFromConfirmAt } from '../../utils/time'

function getOpticsContext () {
  switch (process.env.VUE_APP_OPTICS_ENVIRONMENT) {
    case 'production':
      return mainnet

    case 'productionCommunity': {
      const mainnetCommunityDomainsWithoutAvalanche = mainnetCommunityDomains
        .filter((x) => x.name.toLowerCase() !== 'avalanche')
      return OpticsContext.fromDomains(mainnetCommunityDomainsWithoutAvalanche)
    }

    case 'stagingCommunity':
      return stagingCommunity

    default:
      return devCommunity
  }
}

async function instantiateOptics () {
  // configure for mainnet/testnet
  const opticsContext: OpticsContext = getOpticsContext()

  // get signer
  const provider = new ethers.providers.Web3Provider(
    (window as any).ethereum
  )
  const signer = provider.getSigner()

  // register rpc provider and signer for each network
  for (const n in networks) {
    const network = networks[n]
    const networkName = network.name.toLowerCase()
    opticsContext.registerRpcProvider(
      networkName,
      network.rpcUrl
    )
  }

  const { chainId } = await provider.ready
  const network = getNetworkByChainID(chainId).name.toLowerCase()

  opticsContext.registerSigner(network, signer)

  return opticsContext
}

function getStatusText (status: number): string {
  switch (status) {
    case 0:
      return 'Dispatched'
    case 1:
      return 'Included'
    case 2:
      return 'Relayed'
    case 3:
      return 'Processed'
    default:
      return 'Unknown'
  }
}

let optics: OpticsContext

export interface SendData {
  isNative: boolean,
  originNetwork: number,
  destNetwork: number,
  asset: TokenIdentifier,
  amnt: number,
  recipient: string,
  gasLimit?: number
}

export interface ProviderState {
  balance: BigNumber,
  sending: boolean
}

const state: ProviderState = {
  balance: BigNumber.from('0'),
  sending: false
}

const mutations = <MutationTree<ProviderState>>{
  [types.SET_BALANCE] (state: ProviderState, balance: BigNumber) {
    console.log('{dispatch} set balance: ', balance)
    state.balance = balance
  },

  [types.SET_SENDING] (state: ProviderState, sending: boolean) {
    console.log('{dispatch} transaction send in process: ', sending)
    state.sending = sending
  }
}

const actions = <ActionTree<ProviderState, any>>{
  async instantiateOptics ({ rootState }) {
    // if window.ethereum does not exist, do not instantiate optics
    const { ethereum } = window as any
    if (!ethereum) return

    if (ethereum.isConnected() && !rootState.wallet.networkUnsupported) {
      optics = await instantiateOptics()
    }
  },

  async getBalanceFromWallet ({ rootState, commit }) {
    // get current network domain
    const networkName = rootState.wallet.network.toLowerCase()
    const network = networks[networkName]
    const domain = network.domainID
    const address = rootState.wallet.address
    const token = rootState.wallet.token

    let balance
    if (token.tokenIdentifier.domain === networkName) {
      if (isNativeToken(networkName, token)) {
        balance = await getNativeBalance(optics, network.name, address)
      } else {
        console.log('getting balance of ERC20 token: ', token.name)
        const provider = optics.getProvider(network.name.toLowerCase())
        balance = await getERC20Balance(provider, token.tokenIdentifier.id, address)
      }
    } else {
      console.log('getting representational token balance')
      try {
        balance = await getBalance(optics, token.tokenIdentifier, address, domain)
      } catch (_e) {
        // there is no balance so it errors
        // should return 0
        balance = 0
        console.log(`no balance for ${token.name}`)
      }
    }

    commit(types.SET_BALANCE, balance)
  },

  registerSigner (_, network) {
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    )
    const newSigner = provider.getSigner()
    // @ts-ignore
    optics.clearSigners()
    // @ts-ignore
    const missingProviders = optics.missingProviders
    missingProviders.forEach((domain: number) => {
      const network = getNetworkByDomainID(domain)
      optics.registerRpcProvider(network.name.toLowerCase(), network.rpcUrl)
    })

    optics.registerSigner(network, newSigner)
  },

  async send ({ commit, dispatch }, payload: SendData) {
    console.log('sending...', payload)
    commit(types.SET_SENDING, true)
    const {
      isNative,
      originNetwork,
      destNetwork,
      asset,
      amnt,
      recipient,
      gasLimit
    } = payload

    const originDomain = optics.resolveDomain(originNetwork)
    const destDomain = optics.resolveDomain(destNetwork)

    // NB: 1667591279 is Celo; 1000 is Alfajores Even though Celo is a native
    // token, it is also an ERC-20, and so does not need to be handled like
    // Ethereum or other chains
    const isCelo = originDomain === 1667591279 || originDomain === 1000

    let transferMessage
    if (!isCelo && isNative) {
      console.log('Network is NOT Celo and Asset IS native')
      try {
        transferMessage = await optics.sendNative(originDomain, destDomain, amnt, recipient)
        console.log('tx sent!!!!!!!!!!!!', transferMessage)
      } catch (e: any) {
        dispatch('addErrorPopup', { message: `Your transaction could not be completed. ${e.message}` })
        console.error(e)
      }
    } else {
      console.log('Network is any and asset is NOT native')
      try {
        transferMessage = await optics.send(
          originDomain,
          destDomain,
          asset,
          amnt,
          recipient,
          { gasLimit: gasLimit || 300_000 }
        )
        console.log('tx sent!!!!!!!!!!!!', transferMessage)
      } catch (e: any) {
        dispatch('addErrorPopup', { message: `Your transaction could not be completed. ${e.message}` })
        console.error(e)
      }
    }

    // if transfer was a success, save transaction and alert user
    if (transferMessage) {
      const payload: TXData = {
        network: originNetwork,
        hash: transferMessage.receipt.transactionHash,
        timestamp: Date.now()
      }
      dispatch('addTransaction', payload)
      // Transaction sent by optics; now show popup
      dispatch('addInfoPopup', {
        title: 'Pending Transaction',
        message: 'Transaction has been sent and is pending confirmation.'
      })
    }

    commit(types.SET_SENDING, false)
    dispatch('getBalanceFromWallet')
  }
}

const getBlockNumberOftx = async (context: OpticsContext,
  nameOrDomain: string | number,
  transactionHash: string) => {
  const provider = context.mustGetProvider(nameOrDomain)
  return (await provider.getTransactionReceipt(transactionHash)).blockNumber
}

const getBlockByTimeStamp = async (context: OpticsContext, nameOrDomain: string | number, targetTimestamp: number) => {
  const blockDifference = 10000
  console.info('getting block by timestamp', nameOrDomain)
  const provider = context.mustGetProvider(nameOrDomain)
  console.info('provder')
  console.info('net', await provider.getNetwork())
  const latest = await provider.getBlockNumber()

  const [latestBlock, recentBlock] = await Promise.all([
    provider.getBlock(latest),
    provider.getBlock(latest - blockDifference)
  ])

  const secondsBetweenBlocks = (latestBlock.timestamp - recentBlock.timestamp) / blockDifference

  const secondsBetweenNowAndTargetTimestamp = latestBlock.timestamp - targetTimestamp

  const blocksToGoBack = Math.floor(secondsBetweenNowAndTargetTimestamp / secondsBetweenBlocks)

  let blockGuess = await provider.getBlock(latest - blocksToGoBack)

  let difference = Math.abs(blockGuess.timestamp - targetTimestamp)
  let differenceInBlocks = Math.floor(difference / secondsBetweenBlocks)
  let low = blockGuess.timestamp < targetTimestamp ? blockGuess.number : blockGuess.number - (differenceInBlocks)
  let high = blockGuess.timestamp > targetTimestamp ? blockGuess.number : blockGuess.number + (differenceInBlocks)
  let mid = Math.floor((low + high) / 2)
  while (differenceInBlocks > 2) {
    blockGuess = await provider.getBlock(mid)
    low = blockGuess.timestamp < targetTimestamp ? blockGuess.number : blockGuess.number - (differenceInBlocks)
    high = blockGuess.timestamp > targetTimestamp ? blockGuess.number : blockGuess.number + (differenceInBlocks)
    mid = Math.floor((low + high) / 2)
    difference = Math.abs(blockGuess.timestamp - targetTimestamp)
    differenceInBlocks = Math.floor(difference / secondsBetweenBlocks)
    console.log('low', low, 'high', high, 'mid', mid, 'differenceInBlocks', differenceInBlocks)
  }
  return mid
}

const getters = <GetterTree<ProviderState, any>>{
  getTxMessages: () => async (txs: TXData[]) => {
    const newTxs = await Promise.all(txs.map(async (tx) => {
      const { network, hash } = tx
      // this is the line that fails .
      // eslint-disable-next-line
      // here I can theoretically get the block
      const message = await TransferMessage.singleFromTransactionHash(optics, network, hash)
      // amount as BN
      const amountBN = (message as TransferMessage).amount.toString()
      // get token
      const token = await optics.resolveRepresentation(message.origin, message.token)
      // amount divided by decimals
      const amount = ethers.utils.formatUnits(amountBN, await token!.decimals())
      // token symbol
      const symbol = await token?.symbol()
      // status
      const status = (await message.events()).status

      if (!tx.timestamp) {
        // get time sent by confirmAt timestamp minus confirmation time
        const confirmAt = await message.confirmAt()
        if (!confirmAt.isZero()) {
          tx.timestamp = getTimestampFromConfirmAt(confirmAt)
        }
      }

      return {
        hash,
        origin: optics.resolveDomainName(message.origin),
        destination: optics.resolveDomainName(message.destination),
        amount,
        token: symbol,
        status: getStatusText(status),
        timestamp: tx.timestamp
      }
    }))
    return newTxs
  },

  getTxMessage: (_state: ProviderState, rootGetters) => async (tx: TXData) => {
    const { network, hash } = tx
    console.log('info just before singleFromTransactionHash')

    const message = await TransferMessage.singleFromTransactionHash(optics, network, hash)
    const blockNumberOfOriginalTx = await getBlockNumberOftx(optics, network, hash)
    const blockOfOriginalTransaction = await optics.mustGetProvider(network).getBlock(blockNumberOfOriginalTx)
    console.info('message', message)
    const estimatedEarliestBlockOnReplica = await getBlockByTimeStamp(optics, optics.resolveDomain(message.destination), blockOfOriginalTransaction.timestamp)
    console.log('estimatedEarliestBlockOnReplica', estimatedEarliestBlockOnReplica)
    // amount as BN
    const amountBN = (message as TransferMessage).amount.toString()
    // get token
    const token = await optics.resolveRepresentation(message.origin, message.token)
    // amount divided by decimals
    const amount = ethers.utils.formatUnits(amountBN, await token!.decimals())
    // token symbol
    const symbol = await token?.symbol()
    // status
    // @ts-ignore:next-line
    const status = (await message.events(blockNumberOfOriginalTx, estimatedEarliestBlockOnReplica)).status
    // get confirmAt time as timestamp
    // providing block number of transaction as that is the earliest it could exist at
    // @ts-ignore:next-line -
    const confirmAt = await message.confirmAt(blockNumberOfOriginalTx)

    // get time sent
    let timeSent
    const transaction = await rootGetters.getTx(tx)
    if (transaction) {
      timeSent = transaction.timestamp
    } else {
      // get time sent by confirmAt timestamp minus confirmation time
      if (!confirmAt.isZero()) {
        timeSent = getTimestampFromConfirmAt(confirmAt)
      }
    }

    return {
      hash,
      origin: optics.resolveDomainName(message.origin),
      destination: optics.resolveDomainName(message.destination),
      amount,
      token: symbol,
      status: getStatusText(status),
      timestamp: timeSent,
      leafIndex: message.dispatch.event.args.leafIndex.toNumber(),
      // convert to milliseconds
      confirmAt: confirmAt.mul(BigNumber.from('1000'))
    }
  },

  getReplica: () => (home: string, remote: string) => {
    return optics.mustGetReplicaFor(home, remote)
  },

  resolveDomain: () => (network: string) => {
    return optics.resolveDomain(network)
  },

  resolveDomainName: () => (network: number) => {
    return optics.resolveDomainName(network)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
